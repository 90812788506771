import api from '@/apis/api'
/**
 * 供应商用户相关api
 */
export class ManufacturerApi extends api {


  setState (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/setManufacturerStatus`,
      method: 'put',
      data: form
    })
  }

  changePassword (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/changeManufacturerPassword`,
      method: 'post',
      data: form
    })
  }
  resetPassword (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/resetPassword`,
      method: 'post',
      data: form
    })
  }
  resetPasswordBatch(condition){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/resetPasswordBatch`,
      method: 'post',
      data: condition
    })
  }
  resetStatusBatch (condition) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/setManufacturerStatusBatch`,
      method: 'put',
      data: condition
    })
  }
}

export default new ManufacturerApi('manufacturer')
